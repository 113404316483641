import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"700px","persistent":""},model:{value:(_vm.valueChanger),callback:function ($$v) {_vm.valueChanger=$$v},expression:"valueChanger"}},[_c(VCard,{staticClass:"py-6 pa-4"},[_c(VCardTitle,{staticClass:"title-card justify-center purple--text"},[_vm._v(" "+_vm._s(this.dataUser.koachInfo.name ? `Hola ${this.dataUser.koachInfo.name.split(' ')[0].charAt(0).toUpperCase()}${this.dataUser.koachInfo.name.split(' ')[0].slice(1).toLowerCase()} ${this.dataUser.koachInfo.name.split(' ')[2] | _vm.formatNames || ''} ¿Cómo te sientes hoy?` : 'Hola ¿Cómo te sientes hoy?')+" ")]),_c(VRow,{staticClass:"ma-7",attrs:{"align":"center","justify":"center"}},_vm._l((_vm.emotions),function(item){return _c(VCol,{key:item.text,attrs:{"cols":"2","align":"center"},on:{"click":() => _vm.saveEmotion(item.text)}},[_c(VImg,{staticClass:"reaction",attrs:{"max-height":"45","max-width":"45","src":item.image}}),_c('div',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"subtitle-emotion"},[_vm._v(_vm._s(item.text))])])],1)}),1),_c(VCardText)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }