import store from "../store";

const getRoles = {
  computed: {
      isAdmin() {
        return store.state.dataUser.koachInfo.role === 'admin';
      },
      isSuperior() {
        return store.state.dataUser.koachInfo.role === 'superior';
      },
      isLeader() {
        return store.state.dataUser.koachInfo.role === 'leader';
      },
      isAsesor() {
        return store.state.dataUser.koachInfo.role === "asesor";
      },
  },
};

export default getRoles;