/* eslint-disable max-len */
import {
  addData, getData,
} from './utils';

const hostDev = process.env.VUE_APP_HOST_KOACH;

async function getUsersPhotos(arrayDocument, accesToken, recaptchaToken) {
  const API_URL = `${hostDev}/users/photo?documents=${arrayDocument}&token=${recaptchaToken}`;
  return getData(API_URL, accesToken);
}

async function recordUserMood(mood, token) {
  const API_URL = `${hostDev}/moods`;
  return addData(API_URL, mood, token);
}

async function getUserMadeRegistrationMood(document, token) {
  const API_URL = `${hostDev}/moods/${document}`;
  return getData(API_URL, token);
}

export {
  getUsersPhotos, recordUserMood, getUserMadeRegistrationMood,
};
