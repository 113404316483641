<template>
<div class="text-center">
    <v-dialog
    :persistent="persistent"
      v-model="dialogOn"
      width="600px"
     >
      <v-card>
        <v-card-actions class="d-flex justify-end">
           <v-icon medium @click="()=> closeDialog()" color="purple" class="mx-5 py-2 btn_close">
            mdi-close-circle
          </v-icon>
        </v-card-actions>
         <div class="d-flex flex-column align-center pa-10 text-center">
          <span class="headline text-sub-message d-flex justify-center" color="lila">
            {{ title }}</span>
          <v-icon v-if="icon" color="purple" x-large class="my-8"
            >{{icon}}
          </v-icon>
          <h4 class="headline text-sub-message d-flex justify-center" color="lila" v-if="text">
            {{text}}
          </h4>
          <v-card-actions v-if="confirm">
              <v-col class=" mx-10">
                <v-btn class="elevation-0 red--text" @click="rejectHandler">No</v-btn>

              </v-col>
              <v-col class="mx-10">
                <v-btn class="elevation-0 lila--text" @click="confirmHandler">Si</v-btn>
              </v-col>
          </v-card-actions>
        </div>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    confirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  // props: ['dialog', 'title', 'icon', 'text', 'confirm', 'cancel', 'persistent'],
  computed: {
    dialogOn() {
      return this.dialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('setDialog', false);
    },
    confirmHandler() {
      this.$emit('confirmHandler');
    },
    rejectHandler() {
      this.$emit('rejectHandler');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/app.scss";
.text-sub-message {
font-family: $body-font-family;
font-size: 22px;
color: $color-1;
}

.btn_close::after{
   content: none;
}
</style>
