<!-- eslint-disable max-len -->
<template>
  <v-dialog v-model="valueChanger" max-width="700px" persistent>
    <v-card class="py-6 pa-4">
      <v-card-title class="title-card justify-center purple--text">
        {{
          this.dataUser.koachInfo.name
          ? `Hola ${this.dataUser.koachInfo.name.split(' ')[0].charAt(0).toUpperCase()}${this.dataUser.koachInfo.name.split(' ')[0].slice(1).toLowerCase()} ${this.dataUser.koachInfo.name.split(' ')[2] | formatNames || ''} ¿Cómo te sientes hoy?`
          : 'Hola ¿Cómo te sientes hoy?'
        }}
      </v-card-title>
      <v-row align="center" justify="center" class="ma-7">
        <v-col
          cols="2"
          v-for="item in emotions"
          align="center"
          @click="() => saveEmotion(item.text)"
          :key="item.text"
        >
          <v-img class="reaction" max-height="45" max-width="45" :src="item.image"></v-img>
          <div class="text-center mt-2">
            <span class="subtitle-emotion">{{ item.text }}</span>
          </div>
        </v-col>
      </v-row>
      <v-card-text> </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import modifyTitle from '../mixins/modifyTitle';
import { formatNames } from '../filters/formatNames';

export default {
  name: 'StateOfMind',
  props: {
    value: {
      type: Boolean,
    },
  },
  filters: {
    formatNames,
  },
  mixins: [modifyTitle],
  data() {
    return {
      emotions: [],
    };
  },
  computed: {
    ...mapState(['dataUser']),
    ...mapState(['dataDynamic']),

    valueChanger() {
      return this.value;
    },
  },
  created() {
    this.emotions = this.dataDynamic.textsAndImages.ModalComoTeSientes;
    this.analyticInitHowYouFeel();
  },
  methods: {
    saveEmotion(emotion) {
      this.$emit('setDialog', emotion);
    },
    analyticInitHowYouFeel() {
      this.$gtag.event(`${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Inicio_Modal_ComoTeSientes`, {
        event_category: `${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Inicio_Modal_ComoTeSientes`,
        event_label: `${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Inicio_Modal_ComoTeSientes`,
        value: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/app";

.title-card {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $color-11;
}
.subtitle-emotion {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: $color-11;
}
.reaction:hover {
  transform: scale(1.25);
  cursor: pointer;
}
</style>
