import dayjs from 'dayjs';

const localeData = require('dayjs/plugin/localeData');
const weekday = require('dayjs/plugin/weekday');
const isoWeek = require('dayjs/plugin/isoWeek');

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isoWeek);

export default {
  methods: {
    dateFormat(date, subtract) {
      return dayjs(date).subtract(subtract, 'days').format('YYYY-MM-DD');
    },
    dateFormatHour(date, subtract) {
      return dayjs(date).subtract(subtract, 'minutes').format('YYYY-MM-DDTHH:mm');
    },
    dateFormatHourDaysAfter(date, subtract) {
      return dayjs(date).add(subtract, 'days').format('YYYY-MM-DDTHH:mm');
    },
    dateHourFormat(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    dateMinutes(date, add) {
      return dayjs(date).subtract(add, 'minutes').format('mm');
    },
    dateHours(date, add) {
      return dayjs(date).subtract(add, 'minutes').format('HH');
    },
    dateNow() {
      return dayjs(new Date()).format('YYYY-MM-DD HH:mm');
    },
    dateNowSlash() {
      return dayjs(new Date()).format('YYYY/MM/DD HH:mm');
    },
    getHourTime(h) {
      return h >= 12 ? 'PM' : 'AM';
    },
    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? '' : '0') + n;
    },
    getCurrentMonth() {
      const number = (new Date()).getMonth();
      return dayjs.localeData('es').months()[number];
    },
    getLastMonday(month, year) {
      const d = new Date();
      if (year) { d.setFullYear(year); }
      d.setDate(1); // Roll to the first day of ...
      d.setMonth(month || d.getMonth() + 1); // ... the next month.
      do { // Roll the days backwards until Monday.
        d.setDate(d.getDate() - 1);
      } while (d.getDay() !== 1);
      return d;
    },
    getLastSunday(month, year) {
      const date = new Date(year, month, 1, 12);
      const weekdays = date.getDay();
      const dayDiff = weekday == 0 ? 7 : weekdays;
      return date;
    },
    getFirstDayOfMonth() {
      return dayjs().startOf('month');
    },
  },
};
