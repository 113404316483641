<template>
  <div class="loading-overlay" v-if="view">
    <div style="display: flex; flex-direction: column; align-items: center" class="loading-view">
      <SyncLoader class="ring-loader" :color="color" :size="size" sizeUnit="px" />
      <p class="mt-4 py-8 text-loading">
        <strong class="text-loading">Un momento por favor, estamos cargando la información.</strong>
      </p>
    </div>
  </div>
</template>

<script>
import { SyncLoader } from "@saeris/vue-spinners";

export default {
  components: { SyncLoader },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    view: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    color: "#7367F0",
    style: "purpleText",
    size: 32,
    margin: "2px",
  }),
  watch: {
    color() {
      setTimeout(() => {
        this.color = this.color === "#7F41E3" ? "#7367F0" : "#7F41E3";
      }, 1000);
    },
  },
  created() {
    setTimeout(() => {
      this.color = "#7F41E3";
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/app.scss";

.loading-view {
  width: 100%;
  .purpleText {
    font-family: $body-font-family;
    font-size: 24px;
    color: $color-37;
  }
  .pinkText {
    font-family: $body-font-family;
    font-size: 24;
    color: $color-4;
  }
  .text-loading {
    font-family: $body-font-family;
    color: $color-37;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.25px;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200; /* Ajusta según sea necesario para estar por encima de otros elementos */
}
</style>
