export default {
  methods: {
    removeLine(text) {
      if (text.includes('_')) {
        return text.replaceAll('In', '').replaceAll('_', ' ').trim();
      }
      return text;
    },
    removeSpaceDot(text) {
      let value;
      const newText = text.replaceAll('.', ' ');
      const arrayText = newText.split(' ');
      if (arrayText.length === 1) {
        value = arrayText[0].slice(0, 4);
      }
      if (arrayText.length === 2) {
        value = `${arrayText[0].slice(0, 3)}_${arrayText[1].slice(0, 3)}`;
      }
      if (arrayText.length === 3) {
        value = `${arrayText[0].slice(0, 3)}_${arrayText[1].slice(0, 3)}_${arrayText[2].slice(0, 1)}`;
      }
      return value;
    },
    removeTilde(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    stractFirstLetter(text) {
      const letter = text.slice(0, 1).toUpperCase();
      return text === 'asesor' ? 'R' : letter;
    },
  },
};
