import Vue from 'vue';
import Vuex from 'vuex';
import { getNotificationsByUser } from '../api/notifications';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dataDynamic: {},
    layout: 'main-layout',
    dataUser: {},
    data: {},
    calendar: {
      element: null,
      eventData: {},
      open: false,
    },
    listenerId: '',
    error: {
      type: '',
    },
    notifications: [],
    confirmFinalized: false,
  },
  mutations: {
    SET_LAYOUT(state, newLayout) {
      state.layout = newLayout;
    },
    SET_DATA_USER(state, newDataUser) {
      state.dataUser = newDataUser;
    },
    SET_UPDATE_TOKEN(state, newToken) {
      state.dataUser.auth.access_token = newToken;
    },
    SET_UPDATE_EXPIRES_AT(state, newTokenExpirationDate) {
      state.dataUser.auth.expires_at = newTokenExpirationDate;
    },
    SET_DATA_DYNAMIC(state, newData) {
      state.dataDynamic = newData;
    },
    SET_CALENDAR(state, newData) {
      state.calendar.element = newData.element;
      state.calendar.eventData = newData.eventData;
      state.calendar.open = newData.open;
    },
    SET_LISTENER(state, payload) {
      state.listenerId = payload;
    },
    SET_ERROR(state, type) {
      state.error.type = type;
    },
    GET_NOTIFICATIONS(state, document) {
      const token = state.dataUser.auth.access_token;
      getNotificationsByUser(document, token).then(
        (result) => {
          state.notifications = result.data;
        },
      );
    },
    GET_CONFIRMATION(state, bool) {
      state.confirmFinalized = bool;
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
});

export default store;
