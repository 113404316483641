<!-- eslint-disable max-len -->
<template>
   <v-navigation-drawer
    class="nav-side"
    app
    permanent
    :expand-on-hover="expand"
    @mouseover.native="over"
    @mouseleave.native="leave"
  >
    <v-list class="d-flex flex-column align-center">
      <br />
      <v-list-item v-if="!img">
        <img src="../assets/logo-koach-responsive.svg" width="50px" alt="logo-koach" />
      </v-list-item>
      <v-list-item v-if="img">
        <img src="../assets/koach.svg" width="105px" alt="logo-koach" />
      </v-list-item>
      <v-list-item v-if="!img">
        <v-list-item-avatar size="50" :class="`my-2 avatar`">
            <v-img :src="classAvatar" />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item v-if="img">
        <v-list-item-avatar size="60" :class="`my-2 avatar`">
            <v-img :src="classAvatar" />
        </v-list-item-avatar>
      </v-list-item>
      <div v-if="img">
        <span class="body-1">{{ this.dataUser.koachInfo.name | shortNames}}</span>
      </div>
      <div v-if="!img">
        <span class="caption">
          {{ `${this.dataUser.koachInfo.name.slice(0, this.dataUser.koachInfo.name.indexOf(' '))}...` }}
        </span>
      </div>
      <div v-if="img">
        <span class="body-1">{{ this.dataUser.koachInfo.account }}</span>
      </div>
      <div v-if="!img">
        <span class="caption">{{ `${this.dataUser.koachInfo.account.slice(0, 5)}...` }}</span>
      </div>
      <div class="py-0 ma-0">
        <span>v.{{ version.nroVersion }}</span>
      </div>
    </v-list>
    <v-list-item-group active-class="white--text text--accent-4">
      <v-list-item link to="/inicio" class="nav-list-item" v-if="!isAdmin && !isSuperiorWithMultiplesAccounts">
        <v-list-item-icon class="icon-navbar">
          <v-icon class="menu-icon" dark>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Inicio </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isAsesor && !isUserWithTeam"
        link
        :to="`/asesor/${this.dataUser.koachInfo.document}`"
        class="nav-list-item"
      >
        <v-list-item-icon class="icon-navbar">
          <v-icon class="menu-icon" dark>mdi-chart-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Resultados </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isLeader || isSuperiorWithOnlyOneAccount"
        link
        :to="isSuperiorWithOnlyOneAccount ? '/indicadores-generales' : '/indicadores'"
        class="nav-list-item"
      >
        <v-list-item-icon class="icon-navbar">
          <v-icon dark class="menu-icon">mdi-chart-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Resultados </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAsesor" link to="/planes-de-accion" class="nav-list-item">
        <v-list-item-icon class="icon-navbar">
          <v-icon class="menu-icon" dark>mdi-clipboard-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item"> Planes de acción </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isLeader" link to="/seguimiento" class="nav-list-item">
        <v-list-item-icon class="icon-navbar">
          <v-icon dark>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Seguimiento </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isAsesor || isLeader || isSuperiorWithOnlyOneAccount"
        link
        to="/notificaciones"
        class="nav-list-item"
      >
        <v-list-item-icon>
          <v-icon dark class="mx-0 px-0">{{ "mdi-bell" }}</v-icon>
          <v-badge v-if="newNotifications" color="pink" dot></v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item px-0 mx-0">Notificaciones </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAsesor || isLeader" link to="/calendario" class="nav-list-item">
        <v-list-item-icon class="icon-navbar">
          <v-icon dark>mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Calendario </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAdmin || isSuperiorWithMultiplesAccounts" link to="/indicadores-generales" class="nav-list-item">
        <v-list-item-icon class="icon-navbar">
          <v-icon dark>mdi-chart-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Administrador </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isUserWithTeam" link to="/sin-indicadores" class="nav-list-item">
        <v-list-item-icon class="icon-navbar">
          <v-icon class="menu-icon" dark>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-item">Inicio </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <template v-slot:append>
      <v-list-item @click="(dialog = false), logout()" class="py-2" dark>
        <v-list-item-icon>
          <v-icon dark>mdi-power-standby</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="logout">
          <v-list-item-title class="name-user">Cerrar Sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { shortNames } from '../filters/shortNames';

export default {
  props: {
    newNotifications: {
      type: Boolean,
    },
  },
  filters: { shortNames },
  data() {
    return {
      userInfo: {},
      dataDynamicApp: {},
      img: true,
      expand: true,
      name: '',
      lastName: '',
      version: {
        nroVersion: '',
      },
    };
  },

  mounted() {
    this.over();
    this.leave();
    this.verifyVersion();
    this.$store.commit(
      'GET_NOTIFICATIONS',
      `?document=${Number(this.dataUser.koachInfo.document)}`,
    );
  },
  computed: {
    ...mapState(['dataUser']),
    ...mapState(['dataDynamic']),
    ...mapState(['listenerId']),

    isSuperiorWithOnlyOneAccount() {
      return this.dataUser.koachInfo.role === 'superior' && this.dataUser.koachInfo.koachAccounts.length === 0;
    },
    isSuperiorWithMultiplesAccounts() {
      return this.dataUser.koachInfo.role === 'superior' && this.dataUser.koachInfo.koachAccounts.length > 0;
    },
    isUserWithTeam() {
      return this.dataUser.koachInfo.role === 'userWithTeam';
    },

    classAvatar() {
      switch (this.dataUser.koachInfo.gender) {
        case 'FEMENINO':
          return this.dataDynamic.textsAndImages.IconFemale;
        case 'MASCULINO':
          return this.dataDynamic.textsAndImages.IconMale;
        default:
          return this.dataDynamic.textsAndImages.IconKonecta;
      }
    },
  },

  methods: {
    getInfoUser() {
      this.userInfo = this.$store.state.dataUser;
    },

    logout() {
      const hostAuth = process.env.VUE_APP_HOST_AUTH;
        const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
        const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI;

        window.location.href = `${hostAuth}/login?client_id=${clientId}&response_type=code&scope=KonectaOneSession%2Fread&redirect_uri=${redirectUri}`;
    },
    verifyVersion() {
      this.version.nroVersion = this.dataDynamic.versions.actualVersion;
    },
    over() {
      this.img = true;
      this.expand = false;
    },
    leave() {
      this.expand = true;
      this.img = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../app";

.nav-side {
  background: linear-gradient(rgba(127, 65, 227, 0.68), rgba(85, 3, 149, 0.1)),
    url("../assets/background-navbar.svg");
  padding-left: 0 !important;
}
::-webkit-scrollbar {
  display: none;
}
.name-user {
  font-size: 14px;
  font-weight: 600;
  font-family: $body-font-family;
}
p {
  color: $color-3;
  font-size: 14px;
  font-family: $body-font-family;
  line-height: 50px;
  font-style: normal;
  font-weight: normal;
}
span {
  color: $color-3;
  font-size: 14px;
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 600;
}
.nav-list-item {
  width: 100%;

  .text-item {
    color: $color-2;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
  i.v-icon.v-icon {
    color: $color-2;
  }

  .title-btn-logout {
    color: $color-3;
  }
}
.norapname{
  width: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}

@media (max-width: 768px) {
  .h1-trk {
    font-size: 13px;
  }
  h2 {
    font-size: 16px;
    color: #2276bb;
  }
  .img-avatar {
    width: 90%;
    height: 90%;
  }
  .button-icons {
    background: blue;
    display: block;
  }
  .icon-navbar {
    padding-left: 15px;
  }
  .text-router {
    width: 0px;
    color: white;
  }
  .name-user {
  font-size: 10px;
  font-weight: 600;
  font-family: $body-font-family;
}
}
</style>
