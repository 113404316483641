<template>
  <div>
 <v-container class="container">

      <div v-if="isLoading" class="content-loading">
         <Loading />
      </div>
    
      <div class="background-image d-flex justify-center align-center">
        <div class="ml-6 mb-4" v-if="dataDynamic && Object.values(dataDynamic).length !== 0">
          <UpdateComponent :dataDynamic="dataDynamic" />
        </div>

        <ModalAction
          icon="mdi-alert-circle"
          :dialog="withoutSuperior"
          title="No registras Líder en Meucci, repórtalo a tu superior inmediato para que realice la actualización mediante ticket OTRS."
          @setDialog="closeModalWithoutSuperior"
        ></ModalAction>
      </div>
    </v-container>

    <div
      v-if="
        !isLoading &&
        Object.values(dataUser).length !== 0 &&
        Object.values(dataDynamic).length !== 0
      "
    >
      <component :is="layout"></component>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UpdateComponent from "./components/UpdateVersion.vue";
import ModalAction from "./components/ModalAction.vue";
import { authValidate } from "./api/auth2";
import { getApiDataDynamic } from "./api/dataDynamic";
import MainLayout from "./layouts/Main.vue";
import Loading from "./components/Loading.vue";
import Cookies from 'js-cookie';

export default {
  name: "App",
  components: {
    MainLayout,
    Loading,
    UpdateComponent,
    ModalAction,
  },
  data() {
    return {
      codigoURL: '',
      dataRequest: {},
      dataDynamicRequest: {},
      codigoDeRuta: null,
      redirectDone: false,
      isLoading: false,
      withoutSuperior: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => {
      const { code } = to.query;
      next((vm) => {
        vm.codigoDeRuta = code;
      });
    }, 6000);
  },

  async created() {
    this.$store.commit("SET_LAYOUT", "main-layout");
  },

  computed: {
    ...mapState(["layout"]),
    ...mapState(["dataUser"]),
    ...mapState(["updateToken"]),
    ...mapState(["dataDynamic"]),
  },

  async mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      const { auth, authValidateUser } = this.dataUser;
      const isCodePresent = code && !auth;

      if (isCodePresent) {
          await this.handleCodeValidation(code);
      } else if (!code && auth) {
          this.clearUrlParams();
      } else {
          this.redirectToLogin();
      }
  },

  methods: {

    async handleCodeValidation(code) {
        await this.authValidateUser({ code });
        this.clearUrlParams();
    },

    clearUrlParams() {
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    },

    redirectToLogin() {
        const hostAuth = process.env.VUE_APP_HOST_AUTH;
        const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
        const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI;
        const loginUrl = `${hostAuth}/login?client_id=${clientId}&response_type=code&scope=KonectaOneSession%2Fread&redirect_uri=${redirectUri}`;
        console.log('login', loginUrl);
        window.location.href = loginUrl;
    },

    async authValidateUser(code) {
      this.isLoading = true;
      try {
        this.dataRequest = await authValidate(code);
        this.$store.commit("SET_DATA_USER", this.dataRequest.data);
        window.$store = this.$store;

        // console.log('TEST PIPELINE')
        await this.getDataDinamic();
        await this.getUser();
      } catch (error) {
        if (error.message === "Failed to fetch") {
          if (navigator.onLine) {
            this.error =
              "Error en el servidor, verifica que tu VPN se encuentre activa o que te tengas acceso a la red de Konecta";
          } else {
            this.error = "Verifica que tengas acceso a internet";
          }
        } else if (error.message.includes("Contraseña")) {
          this.error = "Contraseña o usuario incorrecto";
        } else {
          const hostAuth = process.env.VUE_APP_HOST_AUTH;
          const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
          const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI;
          window.location.href = `${hostAuth}/login?client_id=${clientId}&response_type=code&scope=KonectaOneSession%2Fread&redirect_uri=${redirectUri}`;
        }
      } finally {
        this.isLoading = false;
      }
    },

    async getDataDinamic() {
      try {
        if (this.dataRequest.data) {
          this.dataDynamicRequest = await getApiDataDynamic(
            this.dataRequest.data.auth.access_token
          );
          this.$store.commit("SET_DATA_DYNAMIC", this.dataDynamicRequest.data);
        }
      } catch (error) {
        console.error("Error al obtener datos dinámicos:", error);
      }
    },

    async getUser() {
      const userDataKoach = this.dataRequest.data.koachInfo;
      if (
        userDataKoach.role === "admin" ||
        (userDataKoach.role === "superior" && userDataKoach.koachAccounts.length > 0)
      ) {
        this.supremacyAdmin(userDataKoach);
      } else {
        try {
          const docSuperiores =
            this.dataRequest.data.koachInfo.superiors === null
              ? []
              : this.dataRequest.data.koachInfo.superiors.filter(
                  (e) => Number(e.document) !== Number(document)
                );

          const user = {
            ...this.dataRequest.data.koachInfo,
            superiors: docSuperiores,
          };
          this.goKoachInside(user);
          if (this.dataRequest.data.koachInfo.superiorInmediatoDocumento === null) {
            this.isLoading = false;
            this.withoutSuperior = true;
          } else {
            this.goKoachInside(user);
          }
        } catch (e) {
          console.log(e);
          this.isLoading = false;
          if (e.message === "Failed to fetch") this.error = "Error en el servidor";
          else {
            this.error = e.message;
          }
        }
      }
    },

    closeModalPermission() {
      this.analyticModalPermision();
    },

    supremacyAdmin(data) {
      this.isLoading = false;
      this.$router.replace({
        name: "SuperiorAndNewAdmin",
        params: {
          id: data.document,
        },
      });
      this.$store.commit("SET_COMECHAT", false);
      this.$store.commit("GET_NOTIFICATIONS", `?document=${Number(data.document)}`);
    },

    getViewByRole(role) {
      switch (role) {
        case "leader":
          return "Home";
        case "asesor":
          return "Home";
        case "superior":
          return "Home";
        case "userWithTeam":
          return "WithoutIndicators";
        default:
          return "WorkPlan";
      }
    },

    goKoachInside(user) {
      const name = this.getViewByRole(user.role);
      this.$router.replace({
        name,
        params: {
          id: user.document,
        },
      });
    },

    closeModalWithoutSuperior() {
      this.analyticWithoutSuperior();
      this.withoutSuperior = false;
      this.goKoachInside(this.user);
    },
  },
};
</script>

<style lang="scss">
@import "./_app.scss";
html,
body,
.v-aplication,
.v-application .text-md-body-2 {
  font-family: $body-font-family !important;
}

.content-loading {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.loading-view[data-v-efe9d588] {
  max-width: 100%;
  height: 0% !important;
  text-align: center;
  margin-top: 0px !important;
}

.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;

  .alert {
    max-width: 250px;
    margin-left: 5%;
  }
  .alert-text {
    font-size: 12px;
    color: $color-15;
  }
  .texto-recordatorio {
    position: absolute;
    left: 56.44%;
    right: 12.33%;
    top: 65.62%;
    bottom: 31.8%;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: $color-17;
  }
}
</style>
