import store from "../store";
import dayjs from 'dayjs';
import { refreshToken } from "./auth2";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const dataReturn = (resp, data) => {
  switch (resp.status) {
    case 200:
      return data;
    case 400:
      throw new Error("Error 400 - La URL solicitada no se encontró en este servidor");
    case 401:
      throw new Error("Error 401 -  El token de autorización no es válido para obtener sus datos");
    case 404:
      throw new Error("Error 404 - Página no encontrada");
    case 422:
      throw new Error("Error 422 - Contraseña o usuario incorrecto");
    case 500:
      throw new Error("Error 500 - Error de servidor interno");
    default:
      throw new Error(resp.statusText);
  } 
};



async function HasTokenBeenRefreshed() {
  try {
    const fechaActualUTC = dayjs().valueOf();
  const tokenExpiresAt =  dayjs(store.state.dataUser.auth.expires_at).valueOf();

  if (fechaActualUTC >=tokenExpiresAt ) {
    const refreshTokenData = {
      refreshToken: store.state.dataUser.auth.refresh_token,
    };
    const newTokenResponse = await refreshToken(refreshTokenData);

    if (newTokenResponse && newTokenResponse.data.access_token) {
      store.commit("SET_UPDATE_TOKEN", newTokenResponse.data.access_token);
      store.commit("SET_UPDATE_EXPIRES_AT", newTokenResponse.data.expires_at);
      return true;
    } else {
        const hostAuth = process.env.VUE_APP_HOST_AUTH;
        const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
        const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI;

        window.location.href = `${hostAuth}/login?client_id=${clientId}&response_type=code&scope=KonectaOneSession%2Fread&redirect_uri=${redirectUri}`;
    }
  }

  return false;
  } catch (error) {
    const hostAuth = process.env.VUE_APP_HOST_AUTH;
    const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
    const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI;
    window.location.href = `${hostAuth}/login?client_id=${clientId}&response_type=code&scope=KonectaOneSession%2Fread&redirect_uri=${redirectUri}`;
  }
  
}


export async function getApi(API_URL, token) {
  const isRefresh = await HasTokenBeenRefreshed();
  if (isRefresh) {
    return await getApi(API_URL, store.state.dataUser.auth.access_token);
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const resp = await fetch(API_URL, requestOptions);
  const data = await resp.json();
  return dataReturn(resp, data);
}

export async function addData(API_URL, valueChange, token) {
  const isRefresh = await HasTokenBeenRefreshed();
  if (isRefresh) {
    return await addData(API_URL, valueChange, store.state.dataUser.auth.access_token);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(valueChange),
  };
  const resp = await fetch(API_URL, requestOptions);
  const data = await resp.json();
  return dataReturn(resp, data);
}

export async function getData(API_URL, token) {
  const isRefresh = await HasTokenBeenRefreshed();
  if (isRefresh) {
    return await getData(API_URL, store.state.dataUser.auth.access_token);
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const resp = await fetch(API_URL, requestOptions);
  const data = await resp.json();
  return dataReturn(resp, data);
}

export async function addData2(API_URL, valueChange) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(valueChange),
  };
  const resp = await fetch(API_URL, requestOptions);
  const data = await resp.json();
  return dataReturn(resp, data);
}
