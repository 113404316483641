/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { addData2 } from './utils';

const host = process.env.VUE_APP_HOST_KOACH;

export async function authValidate(codeUser) {

  /**
   * Los parámetros que hemos obtenido a través de la URL
   *  - @param valueChange {Object}
   *  @returns {Object}
   */
  const API_URL = `${host}/auth/login`;
  return addData2(API_URL, codeUser);
}

export async function refreshToken(tokenUser) {
  /**
   * Los parámetros que hemos obtenido a través de la URL
   *  - @param valueChange {Object}
   *  @returns {Object}
   */
  const API_URL = `${host}/auth/refreshToken`;
  return addData2(API_URL, tokenUser);
}
