const formatNames = (fullName) => {
  if (!fullName) {
    return '';
  }

  const palabras = fullName.split(' ');

  const nombreFormateado = palabras
    .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase())
    .join(' ');

  return nombreFormateado;
};

export {
  formatNames,
};
