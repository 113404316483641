import Vue from 'vue';
import excel from 'vue-excel-export';
import { Datetime } from 'vue-datetime';
import VueApexCharts from 'vue-apexcharts';
import { VueSpinners } from '@saeris/vue-spinners';
import VueGtag from 'vue-gtag';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import router from './router';
import 'vue-datetime/dist/vue-datetime.css';
import './assets/css/main.css';
import getRoles from './mixins/getRoles';

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_ID_ANALYTICS },
});
Vue.use(VueSpinners);

Vue.use(Datetime);

Vue.use(excel);

Vue.config.productionTip = false;
Vue.prototype.$global = global;

Vue.mixin(getRoles);

Vue.use(
  VueReCaptcha,
  {
    siteKey: process.env.VUE_APP_RECAPTCHA, loaderOptions: { autoHideBadge: true },
  },
);
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
