<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card class="text-center pa-4">
        <h2 class="text-title py-0">La versión de Koach se encuentra desactualizada</h2>
        <v-divider></v-divider>
        <div class="d-flex justify-center my-5"></div>
        <div class="text-content py-4">
          Procederemos a realizar la actualización automáticamente, por favor espera un
          momento.
        </div>
        <p class="text-content py-0">
          Si no se realiza la actualización en breve, presione
          <strong> Ctrl + F5</strong>, borra tus cookies o ingresa desde incógnito.
        </p>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UpdateComponent',
  props: {
    // dataDynamic: {
    //   type: Object,
    // },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState(['dataDynamic']),
  },
  created() {
    this.verifyVersion();
  },
  methods: {
    verifyVersion() {
      const { newVersion } = this.dataDynamic.versions;
      const actualVersion = process.env.VUE_APP_VERSION;
      if (newVersion !== actualVersion) {
        this.dialog = true;
        setTimeout(() => {
          window.location.reload(true);
        }, 8000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/app.scss';
.text-title {
  font-family: $body-font-family;
  color: $color-6;
  font-size: 20px;
}
.text-content {
  font-family: $body-font-family;
  color: $color-1;
}
</style>
