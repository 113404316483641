const shortNames = (names) => {
  if (names.includes(',')) {
    if (names.split(',').length === 2) {
      // eslint-disable-next-line camelcase
      const [f_name] = names.split(',')[0].split(' ');
      const [name] = names.split(',')[1].slice(1).split(' ');
      // eslint-disable-next-line camelcase
      return `${name} ${f_name}`;
    }
    return names;
  }
  const separate = names.split(' ');
  const firstLastName = separate[separate.length - 2];
  const [firstName] = separate;
  return `${firstName} ${firstLastName}`;
};

export {
  shortNames,
};
