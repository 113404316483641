<template>
  <v-app>
     <NavBar v-if="show" v-on:logout="logout" :newNotifications="newNotifications" />
     <StateOfMind :value="dialog" @setDialog="recordEmotion" v-if="isRegisterUser"/>
    <v-main class="v-main ml-12">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from '../components/NavBar.vue';
import StateOfMind from '../components/StateOfMind.vue';
import getDate from '../mixins/getDate';
import modifyTitle from '../mixins/modifyTitle';
import { recordUserMood, getUserMadeRegistrationMood } from '../api/user';

export default {
  mixins: [getDate, modifyTitle],
  components: { NavBar, StateOfMind },
  data: () => ({
    hours: 0,
    minutes: 0,
    seconds: 0,
    hourtime: '',
    today: '',
    show: false,
    isRegisterUser: false,
    dialog: true,
  }),
  computed: {
    ...mapState(['dataUser']),
    ...mapState(['dataDynamic']),
    ...mapState(['notifications']),
    ...mapState(['listenerId']),

    newNotifications() {
      // eslint-disable-next-line array-callback-return, consistent-return
      const thereAreMeetings = this.notifications.filter((e) => {
        if (e.type !== 'REMINDER') {
          const hours = this.hours.toString().length === 1 ? `0${this.hours}` : this.hours;
          const now = `${this.today} ${hours}:${this.minutes}`;
          const meetingHour = e.createdAt;
          const meetingHourAfter = `${this.dateFormatHour(e.createdAt, -60).replace('T', ' ')}`;
          return meetingHourAfter > now && now >= meetingHour;
        }
      });
      return !!thereAreMeetings.length;
    },
  },
  mounted() {
    this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer);
  },
  created() {
    this.validatedMood();
    this.today = this.dateFormat(new Date(), 0);
    if (!this.dataUser.auth.access_token) {
      this.logout();
    }
    setTimeout(() => {
      this.show = true;
    }, 1200);
  },
  methods: {
    analyticOpcSelectModalEmotion(emotion) {
      this.$gtag.event(`${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Modal_Opc_${emotion}`, {
        event_category: `${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Modal_Opc_${emotion}`,
        event_label: `${this.removeSpaceDot(this.dataUser.koachInfo.account)}_${this.stractFirstLetter(this.dataUser.koachInfo.role)}_Modal_Opc_${emotion}`,
        value: 1,
      });
    },
    logout() {
      this.$store.commit('SET_TOKEN', null);
      this.$router.replace('/');
    },
    updateDateTime() {
      const now = new Date();
      this.hours = now.getHours();
      this.minutes = this.getZeroPad(now.getMinutes());
      this.seconds = this.getZeroPad(now.getSeconds());
      this.hourtime = this.getHourTime(this.hours);
      this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
    },
    async validatedMood() {
      const isRegister = await getUserMadeRegistrationMood(`${Number(this.dataUser.koachInfo.document)}`, this.dataUser.auth.access_token);
      this.isRegisterUser = !isRegister.data.existRecordToday;
    },
    async recordEmotion(emotion) {
      this.dialog = false;
      const userEmotion = {
        mood: emotion,
        name: this.dataUser.koachInfo.name,
        document: `${Number(this.dataUser.koachInfo.document)}`,
        account: this.dataUser.koachInfo.account,
        subArea: this.dataUser.koachInfo.subArea,
        service: this.dataUser.koachInfo.service,
      };
      try {
        await recordUserMood(userEmotion, this.dataUser.auth.access_token);
        this.analyticOpcSelectModalEmotion(emotion);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../app';
.v-main {
  padding: 0px 0px 0px 20px !important;

 }
::-webkit-scrollbar {
  display: none;
}
.name-user {
  font-size: 14px;
  font-weight: 600;
  font-family: $body-font-family;
  // font-weight: bold;
}
p {
  color: $color-3;
  font-size: 14px;
  font-family: $body-font-family;
  line-height: 50px;
  font-style: normal;
  font-weight: normal;
}

@media (max-width: 768px) {
  .h1-trk {
    font-size: 13px;
  }
  h2 {
    font-size: 16px;
    color: #2276bb;
  }
  .img-avatar {
    width: 90%;
    height: 90%;
  }
  .button-icons {
    background: blue;
    display: block;
  }
  .icon-navbar {
    padding-left: 15px;
  }
  .text-router {
    // font-size: 0px;
    width: 0px;
    color: white;
  }
}
</style>
