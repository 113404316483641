import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{staticClass:"container"},[(_vm.isLoading)?_c('div',{staticClass:"content-loading"},[_c('Loading')],1):_vm._e(),_c('div',{staticClass:"background-image d-flex justify-center align-center"},[(_vm.dataDynamic && Object.values(_vm.dataDynamic).length !== 0)?_c('div',{staticClass:"ml-6 mb-4"},[_c('UpdateComponent',{attrs:{"dataDynamic":_vm.dataDynamic}})],1):_vm._e(),_c('ModalAction',{attrs:{"icon":"mdi-alert-circle","dialog":_vm.withoutSuperior,"title":"No registras Líder en Meucci, repórtalo a tu superior inmediato para que realice la actualización mediante ticket OTRS."},on:{"setDialog":_vm.closeModalWithoutSuperior}})],1)]),(
       !_vm.isLoading &&
       Object.values(_vm.dataUser).length !== 0 &&
       Object.values(_vm.dataDynamic).length !== 0
     )?_c('div',[_c(_vm.layout,{tag:"component"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }