import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routerOptions = [
  { name: 'Inicio', path: '/', redirect: { name: 'Home' } },
  { name: 'Home', path: '/inicio' },
  { name: 'Indicators', path: '/indicadores' },
  { name: 'Notificaciones', path: '/notificaciones' },
  { name: 'Seguimiento', path: '/seguimiento' },
  { name: 'SeguimientoDetalle', path: '/seguimiento-detalle/:id', params: true },
  { name: 'SeguimientoPlanDeAccion', path: '/seguimiento-detalle-asesor/:asesorID/:diagID' },
  { name: 'Calendar', path: '/calendario' },
  { name: 'Asesor', path: '/asesor/:id' },
  { name: 'Error', path: '/error' },
  { name: 'PlanesDeAccion', path: '/planes-de-accion' },
  { name: 'WithoutIndicators', path: '/sin-indicadores' },
  { name: 'SuperiorAndNewAdmin', path: '/indicadores-generales' },
];

const routes = routerOptions.map((r) => ({
  ...r,
  component: () => import(/* webpackChunkName: "[request]" */`@/views/${r.name}/Index.vue`),
}));

const router = new Router({
  routes,
});

export default router;
