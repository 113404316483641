import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":_vm.persistent,"width":"600px"},model:{value:(_vm.dialogOn),callback:function ($$v) {_vm.dialogOn=$$v},expression:"dialogOn"}},[_c(VCard,[_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VIcon,{staticClass:"mx-5 py-2 btn_close",attrs:{"medium":"","color":"purple"},on:{"click":()=> _vm.closeDialog()}},[_vm._v(" mdi-close-circle ")])],1),_c('div',{staticClass:"d-flex flex-column align-center pa-10 text-center"},[_c('span',{staticClass:"headline text-sub-message d-flex justify-center",attrs:{"color":"lila"}},[_vm._v(" "+_vm._s(_vm.title))]),(_vm.icon)?_c(VIcon,{staticClass:"my-8",attrs:{"color":"purple","x-large":""}},[_vm._v(_vm._s(_vm.icon)+" ")]):_vm._e(),(_vm.text)?_c('h4',{staticClass:"headline text-sub-message d-flex justify-center",attrs:{"color":"lila"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.confirm)?_c(VCardActions,[_c(VCol,{staticClass:"mx-10"},[_c(VBtn,{staticClass:"elevation-0 red--text",on:{"click":_vm.rejectHandler}},[_vm._v("No")])],1),_c(VCol,{staticClass:"mx-10"},[_c(VBtn,{staticClass:"elevation-0 lila--text",on:{"click":_vm.confirmHandler}},[_vm._v("Si")])],1)],1):_vm._e()],1),_c(VCardActions)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }