import { addData, getData } from './utils';

const host = process.env.VUE_APP_HOST_KOACH;

export async function getNotificationsByUser(params, token) {
  const API_URL = `${host}/notifications${params}`;
  return getData(API_URL, token);
}

export async function deleteNotifications(objData, token) {
  /**
     * Los parámetros que hemos obtenido a través de la URL
     *  - @param objData {Object}
     *  @returns {Object}
     */
  const API_URL = `${host}/notifications/delete`;
  return addData(API_URL, objData, token);
}

export async function archiveNotifications(objData, token) {
  /**
     * Los parámetros que hemos obtenido a través de la URL
     *  - @param objData {Object}
     *  @returns {Object}
     */
  const API_URL = `${host}/notifications/update`;
  return addData(API_URL, objData, token);
}
