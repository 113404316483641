// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        indigo: '#F6F2FB',
        pink: '#FA46B3',
        purple: '#7F41E3',
        headerColor: '#EDE7F6',
        panelColor: '#FBFBFB',
        headerColorTable: '#7F41E3',
        colorBarra: '#00b8ad',
        lila: '#7367F0',
        greenAcua: '#00968F',
      },
    },
  },
});
